<template>
    <div class="container content">
        <div>
        <b-breadcrumb separator="has-succeeds-separator" size="is-medium">
            <b-breadcrumb-item tag='router-link' to="/admin-dashboard">Admin</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/admin-education-partners">Koulutuskumppanit</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' :to="'/admin-education-partners/'+$route.params.id" active>{{ company_name }}</b-breadcrumb-item>
        </b-breadcrumb>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Koulutuskumppanin tiedot</p>
                    <div class="columns">
                        <div class="column is-9">
                            <b-field label="Yritys">
                                <b-input v-model="company.name"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-3">
                            <b-field label="Y-tunnus">
                                <b-input v-model="company.vat"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <b-field label="Osoite">
                                <b-input v-model="company.address"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Postinumero">
                                <b-input v-model="company.zip"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-6">
                            <b-field label="Kunta">
                                <b-input v-model="company.city"></b-input>
                            </b-field> 
                        </div>
                    </div>
                   
                        <b-button @click="saveCompany" size="is-medium">
                           Tallenna muutokset
                        </b-button>
            
                </article>            
            </div>
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Koulutuskumppanin käyttäjätilit</p>
                    <b-table hoverable :data="users" :columns="user_columns"  @click="openUserCard"></b-table>
                    <div style="text-align: right; margin-top: 30px;">
                        <b-button size="is-medium"
                            @click="openNewUserModal"
                            icon-left="plus">
                            Uusi käyttäjä
                        </b-button>
                    </div>
                </article>
            </div>
        </div>  
        <div class="tile is-ancestor">
          
            <div class="tile is-parent is-12">
                <article class="tile is-child box">
                    <p class="title">Koulutukset</p>
                    <div style="float: right; margin-top:35px">
                        <b-button size="is-medium"
                            @click="openAddCourseModal"
                            icon-left="plus">
                            Lisää koulutus                        
                        </b-button>
                    </div>
                    <b-table hoverable :data="courses" :columns="course_columns"  @click="openCourseCard"></b-table>
                </article>
            </div>
        </div>

        <b-modal
            v-model="modals.new_user"
            has-modal-card
            full-screen
            :can-cancel="true">
            <NewUserForm :company="company" />
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/eventBus';
import NewUserForm from '@/views/admin/forms/NewUser'
export default {
        components: {
            NewUserForm,
        },
        data() {
            return {
                modals: {
                    new_course: false,
                    new_user: false,
                },
                company_name: "",
                company: {
                    id: "",
                    name: "",
                    vat: "",
                    address: "",
                    city: "",
                    zip: "",
                    billing_type: "paper",
                    e_invoice_address: "",
                    e_invoice_provider: "",
                    return: 0,
                },
                users: [],
                courses: [],
                course_columns: [
                    {
                        field: 'name',
                        label: 'Nimi'
                    },
                    {
                        field: 'price',
                        label: 'Hinta'
                    },
                ],
                user_columns: [
                    {
                        field: 'first_name',
                        label: 'Nimi'
                    },
                    {
                        field: 'last_name',
                        label: 'Sukunimi'
                    },
                    {
                        field: 'email',
                        label: 'Sähköposti'
                    },
                    {
                        field: 'mobile',
                        label: 'Puhelinnumero'
                    }
                ]
            }
        },
        methods: {
            openCourseCard(course) {
                console.log(course);
            },
            updateCourseList() {

            },
            updateCompanyData() {
                axios.get('/education-partners/'+ this.$route.params.id).then((response) => {
                    this.company = response.data;
                    this.company_name = response.data.name;
                });
            },
            saveCompany() {
                axios.put('/education-partner/'+ this.$route.params.id, this.company).then((response) => {
                    this.company = response.data;
                    this.company_name = response.data.name;
                });
            },
            openAddCourseModal() {
                this.modals.new_course = true;
            },
            openNewUserModal() {
                this.modals.new_user = true;
            },
            updateUserList() {
                axios.get('/education-partners/'+ this.$route.params.id+'/users').then((response) => {
                    this.users = response.data;
                });
            },
            openUserCard(user) {
                this.$router.push({ name: 'AdminPartnerUser', params: { id: this.company.id, userId: user.id }});
            },
        },
        computed: {

        },
        mounted() {
            this.updateCompanyData();
            this.updateUserList();    
            this.updateCourseList();     
        },
        created() {
            eventBus.$on('admin-created-user',(() => {
                this.updateUserList();
            }))
            eventBus.$on('admin-created-course',(() => {
                this.updateCourseList();
            }))
        }
    }
</script>
<style>
.breadcrumb {
    margin-bottom: 20px;
}
.breadcrumb li {
    margin-top: 0px !important;
}
tr {
    cursor: pointer;
}

.is-hoverable tr:hover {
    background-color: #cecece !important;
}

</style>